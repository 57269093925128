import Swal from 'sweetalert2'
import Colors from '../design/colors'
import { gettext } from '../utils/translation'
import {
	getMessageText,
	internalError,
	initErrorMessageEventListeners,
} from './messages'

/*
	!!!
		- SweetAlerts are now only used from this file. "Swal.fire" should be nowhere else used in the project.
		- All sweetAlerts should use a messageCode and getting their content from messagingTools.py if possible (in most cases it is possible if you´re not lazy).
		- These Alerts are NOT for handling the responses from the backend. In that case we are still using the successMessage() and the internatError() functions.
	!!!

	--------- ALERTS ----------
	- alertSuccess --> THIS IS A TOAST
	- alertSuccessConfirmation
	- alertWarning
	- alertWarningConfirmation
	- alertDanger
	- alertDangerConfirmation
	- alertInformation
	- alertChoice
	- alertAsyncTask --> FOR ASYNC TASKS (CELERY)
	- alertCustom --> ONLY FOR EDGE-CASES
	---------------------------
*/

const toastSettings = {
	toast: true,
	position: 'top-end',
	timer: 2500,
	timerProgressBar: true,
}

const buttonAriaLabels = {
	confirmButtonAriaLabel: gettext('Bestätigen'),
	denyButtonAriaLabel: gettext('Ablehnen'),
	cancelButtonAriaLabel: gettext('Abbrechen/Schließen'),
	closeButtonAriaLabel: gettext('Schließen'),
}

/**
 * close currently opened alert (sweetAlert)
 */
export function alertClose() {
	Swal.close()
}

/**
 * initialize message from messagingTools and build swal PopUp by given config-Data
 * ! This function should not be used outside this file, use the already written wrappers for alertWarning, alertDanger etc!
 *
 * @param {string} messageCode - the messageCode to get the real Message from messagingTools.py
 * @param {string} messageText - the messageText if we dont have a messageCode
 * @param {object} messageConfig - the config-data for the messagingTools.py (dependent on the messageCode)
 * @param {string} [messageConfig.messageData=''] - the text which is prefixed to the message
 * @param {string} [messageConfig.locationIdentifier=''] - current url (only needed if shortDescriptionOnly=false)
 * @param {boolean} [messageConfig.shortDescriptionOnly=false] - get extended errorData from whatIsMyBrowserApi if true
 * @param {object} swalConfig - the config for the SweetAlert
 * @param {string} [swalConfig.title] - the title
 * @param {string} [swalConfig.icon] - the icon
 * @param {number} [swalConfig.timer=0] - time swal will be visible
 * @param {boolean} [swalConfig.backdrop=true] - backdrop is set?
 * @param {boolean} [swalConfig.showConfirmButton=true] - show confirm button ?
 * @param {string} [swalConfig.confirmButtonText='Ok'] - text of confirm button
 * @param {string} [swalConfig.confirmButtonColor=Colors.Primary] - the color of the confirmButton
 * @param {boolean} [swalConfig.showCancelButton=false] - show cancel button
 * @param {string} [swalConfig.cancelButtonText='Abbrechen'] - text of the cancel button
 * @param {string} [swalConfig.cancelButtonColor=Colors.Secondary] - the color of the cancel button
 * @param {string} [swalConfig.position=''] - position of the swal
 * @param {boolean} [swalConfig.allowEnterKey=true] - dismiss with enter allowed?
 * @param {boolean} [swalConfig.allowOutsideClick=false] - dismiss with outside klick allowed?
 * @param {boolean} [swalConfig.allowEscapeKey=false] -dismiss with escape key press allowed?
 * @param {boolean} [swalConfig.isToast=false] - is the swal a toast?
 * @param {boolean} [swalConfig.isLarge=false] - is the swal a large swal modal?
 * @param {boolean} [swalConfig.isLoader=false] - is the swal a loader info?
 * @returns {Promise} - the SweetAlert promise
 */
async function buildAlert(
	messageCode = undefined,
	messageText = undefined, // text for swal
	{ messageData, locationIdentifier, shortDescriptionOnly = false } = {}, // settings for messagingTools.py
	{
		title,
		icon,
		timer = 0,
		backdrop = true,
		position = 'center',
		isLarge = false, // use customClass large-swal if this option is set
		isLoader = false, // Swal.showLoading() on didOpen
		isToast = false, // convert swal to small towast right-top-end with autoClose
		allowEnterKey = true,
		allowEscapeKey = true,
		allowOutsideClick = false, // dismiss options
		showConfirmButton = true,
		confirmButtonText,
		confirmButtonColor = Colors.Primary, // confirm button settings
		showCancelButton = false,
		cancelButtonText,
		cancelButtonColor = Colors.Secondary, // cancel button settings
	} = {}
) {
	let alert

	let swalConfigFull = {
		title: title,
		icon: icon,
		timer: timer,
		backdrop: backdrop,
		position: position,
		// dismiss options
		allowEnterKey: allowEnterKey,
		allowOutsideClick: allowOutsideClick,
		allowEscapeKey: allowEscapeKey,
		// confirm button settings
		showConfirmButton: showConfirmButton,
		confirmButtonText: confirmButtonText,
		confirmButtonColor: confirmButtonColor,
		// cancel button settings
		showCancelButton: showCancelButton,
		cancelButtonText: cancelButtonText,
		cancelButtonColor: cancelButtonColor,
		// constants
		reverseButtons: true,
		willClose: document.activeElement.blur(),
	}

	// convert swal to toast
	if (isToast) {
		// these are not compatible with toasts
		delete swalConfigFull.backdrop
		delete swalConfigFull.allowOutsideClick
		delete swalConfigFull.allowEnterKey

		swalConfigFull = Object.assign({}, swalConfigFull, toastSettings)
		swalConfigFull.html = `<small>${swalConfigFull.html}</small>`
	}

	if (isLoader) {
		swalConfigFull.timerProgressBar = true
		swalConfigFull.didOpen = () => {
			Swal.showLoading()
		}
	}

	if (isLarge) {
		swalConfigFull.customClass = 'swal-large'
	}

	setButtonAriaLabels(swalConfigFull)

	// get swal html
	if (messageCode !== undefined) {
		// alert by messageCode
		// getMessageText is always called with reducedLayout=false cause we are always in a SweetAlerts here
		const response = await getMessageText(
			messageCode,
			messageData,
			locationIdentifier,
			false,
			shortDescriptionOnly
		)
		if (response.status !== 200) {
			shortDescriptionOnly = false
			alert = internalError({})
		} else {
			const data = await response.json()
			if (!data.success) {
				shortDescriptionOnly = false
				alert = internalError({})
			} else {
				swalConfigFull.html = data.msg
				alert = Swal.fire(swalConfigFull)
			}
		}
	} else if (messageText !== undefined) {
		// alert without messageCode just with normal text
		swalConfigFull.html = messageText
		alert = Swal.fire(swalConfigFull)
	} else {
		// alert with no text (should not be used but we doing this)
		swalConfigFull.html = ''
		alert = Swal.fire(swalConfigFull)
	}

	if (!shortDescriptionOnly) {
		initErrorMessageEventListeners()
	}

	// add dismiss on insideClick eventlistener for toasts
	if (isToast) {
		const swalPopUp = Swal.getPopup()
		swalPopUp.addEventListener('click', () => {
			alertClose()
		})
		swalPopUp.classList.add('cursor-pointer')
	}

	return alert
}

/**
 * set araiLabel for swal button if button is set
 *
 * @param {object} swalConfig - a json config for a swal
 */
function setButtonAriaLabels(swalConfig) {
	if (swalConfig.showConfirmButton) {
		swalConfig.confirmButtonAriaLabel = buttonAriaLabels.confirmButtonAriaLabel
	}
	if (swalConfig.showCancelButton) {
		swalConfig.cancelButtonAriaLabel = buttonAriaLabels.cancelButtonAriaLabel
	}
	if (swalConfig.showDenyButton) {
		swalConfig.denyButtonAriaLabel = buttonAriaLabels.denyButtonAriaLabel
	}
	if (swalConfig.showCloseButton) {
		swalConfig.closeButtonAriaLabel = buttonAriaLabels.closeButtonAriaLabel
	}
}

/**
 * opens a swal-PopUp for a success alert at top-right poistion on screen this alert disapears automaticly and has no confirmation
 * THIS SWAL IS A TOAST
 *
 * @param {string} messageCode - the messageCode to get the real Message from messagingTools.py
 * @param {string} messageText - the messageText if we dont have a messageCode
 * @param {object} config - the config-data for the alert
 * @param {string} [config.messageData=''] - the text which is prefixed to the message
 * @param {string} [config.locationIdentifier=''] - current url (only needed if shortDescriptionOnly=false)
 * @param {boolean} [config.shortDescriptionOnly=false] - get extended errorData from whatIsMyBrowserApi if true
 * @returns {Promise} - the SweetAlert promise
 */
export async function alertSuccess(
	messageCode = undefined,
	messageText = undefined,
	{ messageData, locationIdentifier, shortDescriptionOnly = false } = {}
) {
	const swalConfig = {
		title: `${gettext('Erfolgreich')}!`,
		icon: 'success',
		showConfirmButton: false,
		isLarge: false,
		isToast: true,
	}

	return buildAlert(
		messageCode,
		messageText,
		{ messageData, locationIdentifier, shortDescriptionOnly },
		swalConfig
	)
}

/**
 * opens a swal-PopUp for a success alert in the middle screen this alert alert needs a confirmation
 *
 * @param {string} messageCode - the messageCode to get the real Message from messagingTools.py
 * @param {string} messageText - the messageText if we dont have a messageCode
 * @param {object} config - the config-data for the alert
 * @param {boolean} [config.isLarge] - is the swal a large swal modal?
 * @param {string} [config.showCancelButton=false] - the text which is prefixed to the message
 * @param {string} [config.messageData=''] - the text which is prefixed to the message
 * @param {string} [config.confirmButtonText='Ok'] - if we nnedd another text in confirm than Ok
 * @param {string} [config.locationIdentifier=''] - current url (only needed if shortDescriptionOnly=false)
 * @param {boolean} [config.shortDescriptionOnly=false] - get extended errorData from whatIsMyBrowserApi if true
 * @returns {Promise} - the SweetAlert promise
 */
export async function alertSuccessConfirmation(
	messageCode = undefined,
	messageText = undefined,
	{
		isLarge = false,
		showCancelButton,
		messageData,
		confirmButtonText = 'Ok',
		locationIdentifier,
		shortDescriptionOnly = false,
	} = {}
) {
	const swalConfig = {
		title: `${gettext('Erfolgreich')}!`,
		icon: 'success',
		confirmButtonText: confirmButtonText,
		confirmButtonColor: Colors.Success,
		timer: 0,
		isLarge: isLarge,
	}

	if (showCancelButton) {
		swalConfig.showCancelButton = showCancelButton
		swalConfig.cancelButtonText = gettext('Schließen/Abbrechen')
	}

	return buildAlert(
		messageCode,
		messageText,
		{ messageData, locationIdentifier, shortDescriptionOnly },
		swalConfig
	)
}

/**
 * opens a swal-PopUp for a warning
 *
 * @param {string} messageCode - the messageCode to get the real Message from messagingTools.py
 * @param {string} messageText - the messageText if we dont have a messageCode
 * @param {object} config - the config-data for the alert
 * @param {boolean} [config.isLarge=false] - is the swal a large swal modal?
 * @param {string} [config.messageData=''] - the text which is prefixed to the message
 * @param {string} [config.locationIdentifier=''] - current url (only needed if shortDescriptionOnly=false)
 * @param {boolean} [config.shortDescriptionOnly=false] - get extended errorData from whatIsMyBrowserApi if true
 * @returns {Promise} - the SweetAlert promise
 */
export async function alertWarning(
	messageCode = undefined,
	messageText = undefined,
	{
		isLarge = false,
		messageData,
		locationIdentifier,
		shortDescriptionOnly = false,
	} = {}
) {
	const swalConfig = {
		title: `${gettext('Warnung')}!`,
		icon: 'warning',
		confirmButtonText: 'Ok',
		confirmButtonColor: Colors.Primary,
		isLarge: isLarge,
	}

	return buildAlert(
		messageCode,
		messageText,
		{ messageData, locationIdentifier, shortDescriptionOnly },
		swalConfig
	)
}

/**
 * opens a swal-PopUp for a warning with the option to confirm or decline
 *
 * @param {string} messageCode - the messageCode to get the real Message from messagingTools.py
 * @param {string} messageText - the messageText if we dont have a messageCode
 * @param {object} config - the config-data for the alert
 * @param {boolean} [config.isLarge=false] - is the swal a large swal modal?
 * @param {string} [config.confirmButtonText='Ok'] - if we need a special text for the confirm button
 * @param {string} [config.messageData=''] - the text which is prefixed to the message
 * @param {string} [config.locationIdentifier=''] - current url (only needed if shortDescriptionOnly=false)
 * @param {boolean} [config.shortDescriptionOnly=false] - get extended errorData from whatIsMyBrowserApi if true
 * @returns {Promise} - the SweetAlert promise
 */
export async function alertWarningConfirmation(
	messageCode = undefined,
	messageText = undefined,
	{
		isLarge = false,
		confirmButtonText = 'Ok',
		messageData,
		locationIdentifier,
		shortDescriptionOnly = false,
	} = {}
) {
	const swalConfig = {
		title: `${gettext('Warnung')}!`,
		icon: 'warning',
		confirmButtonText: confirmButtonText,
		confirmButtonColor: Colors.Warning,
		showCancelButton: true,
		cancelButtonText: gettext('Abbrechen'),
		cancelButtonColor: Colors.Secondary,
		allowEnterKey: false,
		isLarge: isLarge,
	}

	return buildAlert(
		messageCode,
		messageText,
		{ messageData, locationIdentifier, shortDescriptionOnly },
		swalConfig
	)
}

/**
 * opens a swal-PopUp for an errorMessage
 *
 * @param {string} messageCode - the messageCode to get the real Message from messagingTools.py
 * @param {string} messageText - the messageText if we dont have a messageCode
 * @param {object} config - the config-data for the alert
 * @param {boolean} [config.isLarge=false] - is the swal a large swal modal?
 * @param {string} [config.messageData=''] - the text which is prefixed to the message
 * @param {string} [config.locationIdentifier=''] - current url (only needed if shortDescriptionOnly=false)
 * @param {boolean} [config.shortDescriptionOnly=false] - get extended errorData from whatIsMyBrowserApi if true
 * @returns {Promise} - the SweetAlert promise
 */
export async function alertDanger(
	messageCode = undefined,
	messageText = undefined,
	{
		isLarge = false,
		messageData,
		locationIdentifier,
		shortDescriptionOnly = false,
	} = {}
) {
	const swalConfig = {
		title: `${gettext('Nicht erfolgreich')}!`,
		icon: 'error',
		confirmButtonText: 'Ok',
		confirmButtonColor: Colors.Primary,
		allowEnterKey: false,
		isLarge: isLarge,
	}

	return buildAlert(
		messageCode,
		messageText,
		{ messageData, locationIdentifier, shortDescriptionOnly },
		swalConfig
	)
}

/**
 * opens a swal-PopUp for a warning before Deletion of on Object with the option to confirm or decline
 *
 * @param {string} messageCode - the messageCode to get the real Message from messagingTools.py
 * @param {string} messageText - the messageText if we dont have a messageCode
 * @param {object} config - the config-data for the alert
 * @param {boolean} [config.isLarge=false] - is the swal a large swal modal?
 * @param {string} [config.confirmButtonText='Ok'] - if we need a special text for the confirm button
 * @param {string} [config.messageData=''] - the text which is prefixed to the message
 * @param {string} [config.locationIdentifier=''] - current url (only needed if shortDescriptionOnly=false)
 * @param {boolean} [config.shortDescriptionOnly=false] - get extended errorData from whatIsMyBrowserApi if true
 * @returns {Promise} - the SweetAlert promise
 */
export async function alertDangerConfirmation(
	messageCode = undefined,
	messageText = undefined,
	{
		isLarge = false,
		confirmButtonText = gettext('Löschen'),
		messageData,
		locationIdentifier,
		shortDescriptionOnly = false,
	} = {}
) {
	const swalConfig = {
		title: gettext('Bitte bestätigen'),
		icon: 'warning',
		confirmButtonText: confirmButtonText,
		confirmButtonColor: Colors.Danger,
		showCancelButton: true,
		cancelButtonText: gettext('Abbrechen'),
		cancelButtonColor: Colors.Secondary,
		allowEnterKey: false,
		isLarge: isLarge,
	}

	return buildAlert(
		messageCode,
		messageText,
		{ messageData, locationIdentifier, shortDescriptionOnly },
		swalConfig
	)
}

/**
 * opens a swal-PopUp for just an information
 *
 * @param {string} messageCode - the messageCode to get the real Message from messagingTools.py
 * @param {string} messageText - the messageText if we dont have a messageCode
 * @param {object} config - the config-data for the alert
 * @param {boolean} [config.isLarge=false] - is the swal a large swal modal?
 * @param {string} [config.messageData=''] - the text which is prefixed to the message
 * @param {string} [config.locationIdentifier=''] - current url (only needed if shortDescriptionOnly=false)
 * @param {boolean} [config.shortDescriptionOnly=false] - get extended errorData from whatIsMyBrowserApi if true
 * @returns {Promise} - the SweetAlert promise
 */
export async function alertInformation(
	messageCode = undefined,
	messageText = undefined,
	{
		isLarge = false,
		messageData,
		locationIdentifier,
		shortDescriptionOnly = false,
	} = {}
) {
	const swalConfig = {
		title: gettext('Hinweis'),
		icon: 'info',
		confirmButtonText: 'Ok',
		confirmButtonColor: Colors.Primary,
		allowOutsideClick: true,
		isLarge: isLarge,
	}

	return buildAlert(
		messageCode,
		messageText,
		{ messageData, locationIdentifier, shortDescriptionOnly },
		swalConfig
	)
}

/**
 * opens a swal-PopUp with a question and with optioins for the user to answer
 * the text in the html of this alert should most be a question to the user
 *
 * @param {string} messageCode - the messageCode to get the real Message from messagingTools.py
 * @param {string} messageText - the messageText if we dont have a messageCode
 * @param {object} config - the config-data for the alert
 * @param {boolean} [config.isLarge=false] - is the swal a large swal modal?
 * @param {string} [config.messageData=''] - the text which is prefixed to the message
 * @param {string} [config.locationIdentifier=''] - current url (only needed if shortDescriptionOnly=false)
 * @param {boolean} [config.shortDescriptionOnly=false] - get extended errorData from whatIsMyBrowserApi if true
 * @param {string} [config.confirmButtonText='Ja'] - text of confirmButtom
 * @param {string} [config.confirmButtonColor='Colors.Primary'] - color of the confirmation button
 * @param {string} [config.cancelButtonText='Nein'] - text of cancelButton
 * @param {string} [config.cancelButtonColor='Colors.Secondary'] - color of cancelButton
 * @returns {Promise} - the SweetAlert promise
 */
export async function alertChoice(
	messageCode = undefined,
	messageText = undefined,
	{
		isLarge = false,
		confirmButtonText = gettext('Ja'),
		cancelButtonText = gettext('Nein'),
		confirmButtonColor = Colors.Primary,
		cancelButtonColor = Colors.Secondary,
		messageData,
		locationIdentifier,
		shortDescriptionOnly = false,
	} = {}
) {
	const swalConfig = {
		title: gettext('Auswahlmöglichkeit'),
		icon: 'question',
		showCancelButton: true,
		confirmButtonText: confirmButtonText,
		confirmButtonColor: confirmButtonColor,
		cancelButtonText: cancelButtonText,
		cancelButtonColor: cancelButtonColor,
		allowEnterKey: true,
		isLarge: isLarge,
	}

	return buildAlert(
		messageCode,
		messageText,
		{ messageData, locationIdentifier, shortDescriptionOnly },
		swalConfig
	)
}

/**
 * opens a swal-PopUp which is used in async tasks
 * these swals in async task are all very simmilar. These swal are always large-swal
 *
 * @param {string} messageCode - the messageCode to get the real Message from messagingTools.py
 * @param {string} messageText - the messageText if we dont have a messageCode
 * @param {object} config - the config-data for the alert
 * @param {string} [config.icon='info'] - the icon of the swal
 * @param {string} [config.title=''] - the title of the swal
 * @param {string} [config.messageData=''] - the text which is prefixed to the message
 * @param {string} [config.locationIdentifier=''] - current url (only needed if shortDescriptionOnly=false)
 * @param {boolean} [config.shortDescriptionOnly=false] - get extended errorData from whatIsMyBrowserApi if true
 * @returns {Promise} - the SweetAlert promise
 */
export async function alertAsyncTask(
	messageCode = undefined,
	messageText = undefined,
	{
		icon = 'info',
		title = '',
		messageData,
		locationIdentifier,
		shortDescriptionOnly = false,
	} = {}
) {
	const swalConfig = {
		icon: icon,
		title: title,
		isLarge: true,
		html: messageText,
		showConfirmButton: false,
		showCancelButton: true,
		cancelButtonText: gettext('Schließen'),
	}

	return buildAlert(
		messageCode,
		messageText,
		{ messageData, locationIdentifier, shortDescriptionOnly },
		swalConfig
	)
}

/**
 * Shows a popup indicating loading progress
 * To close/cancel the loading swal use: alertClose() => this closes the currently opened swal
 *
 * @param {string} messageCode - the messageCode to get the real Message from messagingTools.py
 * @param {string} messageText - the messageText if we dont have a messageCode
 * @param {object} config - the config-data for the alert
 * @param {string} [config.title='Lädt...'] - the title which is prefixed to the message
 * @param {string} [config.messageData=''] - the text which is prefixed to the message
 * @param {string} [config.locationIdentifier=''] - current url (only needed if shortDescriptionOnly=false)
 * @param {boolean} [config.shortDescriptionOnly=false] - get extended errorData from whatIsMyBrowserApi if true
 * @returns {Promise} - the SweetAlert promise
 */
export async function alertLoading(
	messageCode = undefined,
	messageText = undefined,
	{
		title = `${gettext('Lädt')}...`,
		messageData,
		locationIdentifier,
		shortDescriptionOnly = false,
	} = {}
) {
	const swalConfig = {
		icon: 'info',
		title: title,
		showConfirmButton: false,
		timer: 0,
		isLoader: true,
		allowOutsideClick: false,
		allowEnterKey: false,
		allowEscapeKey: false,
	}

	return buildAlert(
		messageCode,
		messageText,
		{ messageData, locationIdentifier, shortDescriptionOnly },
		swalConfig
	)
}

/**
 * builds and opens a custom swal with custom text (use very rarely please!)
 * THIS IS FOR EDGE-CASES
 *
 * @param {object} swalConfig - the config-data for the swal
 * @returns {Promise} - the SweetAlert promise
 */
export async function alertCustom(swalConfig = {}) {
	const settingsConstants = {
		// this never changes
		reverseButtons: true,
		willClose: document.activeElement.blur(),
	}
	const swalConfigFull = Object.assign({}, settingsConstants, swalConfig)

	const alert = Swal.fire(swalConfigFull)
	initErrorMessageEventListeners()
	return alert
}
